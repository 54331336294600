import * as React from "react";
import { Button, Column, Dialog, Heading, Row } from "@lundal/nyx-react";

type Props = {
  onClose: () => void;
};

export function AutoplayBlockedDialog(props: Props): JSX.Element {
  return (
    <Dialog onDismiss={() => props.onClose()}>
      <Column>
        <Heading level={2}>Autoplay Blocked</Heading>
        <p>Your browser is preventing Ambisphere from playing audio.</p>
        <p>
          Please grant Ambisphere permission to play audio and then reload the
          app. If that doesn't work, try a different browser.
        </p>
        <div />
        <Row>
          <Button
            color="white"
            label="Reload"
            onClick={() => window.location.reload()}
          />
          <Button label="Ignore" onClick={() => props.onClose()} />
        </Row>
      </Column>
    </Dialog>
  );
}
