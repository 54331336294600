import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Actives, Ambiance, Error } from "../../api/models";
import { getActives, getAmbiance } from "../../api/ambianceApi";
import { ViewLayout } from "../../components/ViewLayout";
import { useInterval } from "../../hooks/useInterval";
import { VisualViews } from "./VisualViews";
import { SoundGroupPlayers } from "./SoundGroupPlayers";
import { AutoplayBlockedDialog } from "../../dialogs/Autoplay/blocked";
import { useResource } from "../../hooks/useResource";
import { VolumeControl } from "./VolumeControl";

type Params = {
  id: string;
};

export function ViewAmbiancePage(): JSX.Element {
  const params = useParams<Params>();
  const [ambiance, refreshAmbiance] = useResource<Ambiance, Error>(
    () => getAmbiance(params.id),
    [params.id]
  );
  const [actives, refreshActives] = useResource<Actives, Error>(
    () => getActives(params.id),
    [params.id]
  );
  const [soundError, setSoundError] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(50);

  useInterval(refreshAmbiance, 2000);
  useInterval(refreshActives, 1000);

  useEffect(function () {
    const audio = document.getElementById("test-sound") as HTMLAudioElement;
    audio.play().catch(() => setSoundError(true));
  }, []);

  return (
    <ViewLayout>
      {ambiance.type == "Success" && actives.type == "Success" && (
        <>
          <VisualViews ambiance={ambiance.value} actives={actives.value} />
          <SoundGroupPlayers
            ambiance={ambiance.value}
            actives={actives.value}
            volume={volume}
          />
        </>
      )}
      {soundError && (
        <AutoplayBlockedDialog onClose={() => setSoundError(false)} />
      )}
      <VolumeControl volume={volume} setVolume={setVolume} />
    </ViewLayout>
  );
}
