import { del, get, post, put } from "./request";
import {
  Actives,
  Ambiance,
  AmbianceSummary,
  CreateAmbiance,
  CreateSound,
  CreateSoundGroup,
  CreateVisual,
  CreateVisualGroup,
  Move,
  Sound,
  SoundGroup,
  Visual,
  VisualGroup,
} from "./models";

export function createAmbiance(ambiance: CreateAmbiance): Promise<Ambiance> {
  return post("/api/v1/ambiances", ambiance);
}

export function updateAmbiance(
  ambianceId: string,
  ambiance: CreateAmbiance
): Promise<Ambiance> {
  const path = `/api/v1/ambiances/${ambianceId}`;
  return put(path, ambiance);
}

export function createSoundGroup(
  ambianceId: string,
  group: CreateSoundGroup
): Promise<SoundGroup> {
  const path = `/api/v1/ambiances/${ambianceId}/sound-groups`;
  return post(path, group);
}

export function updateSoundGroup(
  ambianceId: string,
  groupId: string,
  group: CreateSoundGroup
): Promise<SoundGroup> {
  const path = `/api/v1/ambiances/${ambianceId}/sound-groups/${groupId}`;
  return put(path, group);
}

export function deleteSoundGroup(
  ambianceId: string,
  groupId: string
): Promise<void> {
  const path = `/api/v1/ambiances/${ambianceId}/sound-groups/${groupId}`;
  return del(path);
}

export function activateSoundGroup(
  ambianceId: string,
  groupId: string
): Promise<void> {
  const path = `/api/v1/ambiances/${ambianceId}/sound-groups/${groupId}/activate`;
  return post(path, null);
}

export function deactivateSoundGroup(
  ambianceId: string,
  groupId: string
): Promise<void> {
  const path = `/api/v1/ambiances/${ambianceId}/sound-groups/${groupId}/deactivate`;
  return post(path, null);
}

export function createSound(
  ambianceId: string,
  groupId: string,
  sound: CreateSound
): Promise<Sound> {
  const path = `/api/v1/ambiances/${ambianceId}/sound-groups/${groupId}`;
  return post(path, sound);
}

export function updateSound(
  ambianceId: string,
  groupId: string,
  soundId: string,
  sound: CreateSound
): Promise<Sound> {
  const path = `/api/v1/ambiances/${ambianceId}/sound-groups/${groupId}/sounds/${soundId}`;
  return put(path, sound);
}

export function deleteSound(
  ambianceId: string,
  groupId: string,
  soundId: string
): Promise<void> {
  const path = `/api/v1/ambiances/${ambianceId}/sound-groups/${groupId}/sounds/${soundId}`;
  return del(path);
}

export function createVisualGroup(
  ambianceId: string,
  group: CreateVisualGroup
): Promise<VisualGroup> {
  const path = `/api/v1/ambiances/${ambianceId}/visual-groups`;
  return post(path, group);
}

export function updateVisualGroup(
  ambianceId: string,
  groupId: string,
  group: CreateVisualGroup
): Promise<VisualGroup> {
  const path = `/api/v1/ambiances/${ambianceId}/visual-groups/${groupId}`;
  return put(path, group);
}

export function deleteVisualGroup(
  ambianceId: string,
  groupId: string
): Promise<void> {
  const path = `/api/v1/ambiances/${ambianceId}/visual-groups/${groupId}`;
  return del(path);
}

export function createVisual(
  ambianceId: string,
  groupId: string,
  visual: CreateVisual
): Promise<Visual> {
  const path = `/api/v1/ambiances/${ambianceId}/visual-groups/${groupId}`;
  return post(path, visual);
}

export function updateVisual(
  ambianceId: string,
  groupId: string,
  visualId: string,
  visual: CreateVisual
): Promise<Visual> {
  const path = `/api/v1/ambiances/${ambianceId}/visual-groups/${groupId}/visuals/${visualId}`;
  return put(path, visual);
}

export function deleteVisual(
  ambianceId: string,
  groupId: string,
  visualId: string
): Promise<void> {
  const path = `/api/v1/ambiances/${ambianceId}/visual-groups/${groupId}/visuals/${visualId}`;
  return del(path);
}

export function moveVisual(
  ambianceId: string,
  groupId: string,
  visualId: string,
  move: Move
): Promise<void> {
  const path = `/api/v1/ambiances/${ambianceId}/visual-groups/${groupId}/visuals/${visualId}/move`;
  return post(path, move);
}

export function activateVisual(
  ambianceId: string,
  groupId: string,
  visualId: string
): Promise<void> {
  const path = `/api/v1/ambiances/${ambianceId}/visual-groups/${groupId}/visuals/${visualId}/activate`;
  return post(path, null);
}

export function deactivateVisual(
  ambianceId: string,
  groupId: string,
  visualId: string
): Promise<void> {
  const path = `/api/v1/ambiances/${ambianceId}/visual-groups/${groupId}/visuals/${visualId}/deactivate`;
  return post(path, null);
}

export function listAmbiances(): Promise<AmbianceSummary[]> {
  return get("/api/v1/ambiances");
}

export function getAmbiance(id: string): Promise<Ambiance> {
  return get("/api/v1/ambiances/" + id);
}

export function deleteAmbiance(id: string): Promise<void> {
  return del("/api/v1/ambiances/" + id);
}

export function getActives(id: string): Promise<Actives> {
  return get("/api/v1/ambiances/" + id + "/actives");
}

export function deleteActives(id: string): Promise<void> {
  return del("/api/v1/ambiances/" + id + "/actives");
}
