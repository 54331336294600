import * as React from "react";
import { CenterLayout, Column, Message } from "@lundal/nyx-react";
import { Header } from "../components/Header";
import { Error } from "../api/models";

type Props = {
  error: Error;
};

export function ErrorPage(props: Props): JSX.Element {
  const header = <Header />;
  const main = (
    <Column element="main">
      <Message type="error" text={props.error.reason} />
    </Column>
  );
  return <CenterLayout header={header} main={main} />;
}
