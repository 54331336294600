import * as React from "react";

type Props = {
  backgroundColor: string;
  containImage: boolean;
  crossfadeMs: number;
  imageUrl: string;
  visible?: boolean;
  above?: boolean;
};

export function VisualView(props: Props): JSX.Element {
  return (
    <div
      style={{
        position: "absolute",
        inset: 0,
        backgroundColor: props.backgroundColor,
        backgroundSize: props.containImage ? "contain" : "cover",
        backgroundImage: `url("${props.imageUrl}")`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        opacity: props.visible == false ? 0 : 1,
        zIndex: props.above == true ? 1 : "auto",
        transition: `opacity ${props.crossfadeMs}ms`,
      }}
    />
  );
}
