import * as React from "react";
import {
  Button,
  Column,
  Dialog,
  Heading,
  Message,
  Row,
} from "@lundal/nyx-react";
import { Form } from "../../components/Form";
import { deleteSound } from "../../api/ambianceApi";
import { Error, Sound } from "../../api/models";
import { useForm } from "../../hooks/useForm";

type Props = {
  ambianceId: string;
  groupId: string;
  sound: Sound;
  onDeleted: () => void;
  onClose: () => void;
};

export function DeleteSoundDialog(props: Props): JSX.Element {
  const form = useForm<{}, {}, void, Error>(
    {},
    () => ({}),
    () => deleteSound(props.ambianceId, props.groupId, props.sound.id),
    () => {
      props.onDeleted();
      props.onClose();
    }
  );

  return (
    <Dialog onDismiss={() => props.onClose()}>
      <Form onSubmit={form.submit}>
        <Column>
          <Heading level={2}>Delete Sound</Heading>
          <p>Are you sure you want to delete "{props.sound.name}"?</p>
          <div />
          <Row>
            <Button color="red" label="Delete sound" />
            <Button label="Cancel" onClick={() => props.onClose()} />
          </Row>
          {form.state.type == "Failure" && (
            <Message type="error" text={form.state.error.reason} />
          )}
        </Column>
      </Form>
    </Dialog>
  );
}
