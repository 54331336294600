import * as React from "react";
import { NavLink } from "react-router-dom";
import {
  CenterLayout,
  Column,
  Heading,
  Message,
  Spinner,
} from "@lundal/nyx-react";
import { Header } from "../../components/Header";
import { AmbianceSummary, Config, Error, Usage } from "../../api/models";
import { listAmbiances } from "../../api/ambianceApi";
import { getUsage } from "../../api/fileApi";
import { toHuman } from "../../utils/NumberUtils";
import { useResource } from "../../hooks/useResource";
import { AmbianceLink } from "./AmbianceLink";

type Props = {
  config: Config;
};

export function SelectAmbiancePage(props: Props): JSX.Element {
  const [ambiances] = useResource<AmbianceSummary[], Error>(listAmbiances);
  const [usage] = useResource<Usage, Error>(getUsage);

  function ambiancesList(): JSX.Element {
    switch (ambiances.type) {
      case "Loading":
        return <Spinner />;
      case "Success":
        if (ambiances.value.length < 1) {
          return <p>No ambiances</p>;
        }
        return (
          <>
            {ambiances.value.map((ambiance) => (
              <p key={ambiance.id}>
                <AmbianceLink ambiance={ambiance} />
              </p>
            ))}
          </>
        );
      case "Failure":
        return <Message type="error" text={ambiances.error.reason} />;
    }
  }

  function storageUsage(): JSX.Element {
    switch (usage.type) {
      case "Loading":
        return <Spinner />;
      case "Success":
        return (
          <p>
            File storage: {toHuman(usage.value.current)} /{" "}
            {toHuman(usage.value.limit)}
          </p>
        );
      case "Failure":
        return <Message type="error" text={usage.error.reason} />;
    }
  }

  const header = <Header apps={props.config.appLinks} />;
  const main = (
    <Column element="main">
      <Heading level={2}>Select Ambiance</Heading>
      {ambiancesList()}
      <p>
        <NavLink className="nyx-link" to="/create">
          Create ambiance
        </NavLink>
      </p>
      {storageUsage()}
    </Column>
  );

  return <CenterLayout header={header} main={main} />;
}
