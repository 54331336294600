import * as React from "react";
import { useEffect, useRef } from "react";
import { Sound, SoundGroup } from "../../api/models";
import { AudioControls, FadingAudio } from "../../utils/FadingAudio";
import { byName, byShuffle } from "../../utils/SortUtils";

type Props = {
  startedMs: number;
  soundGroup: SoundGroup;
  masterVolumePercent: number;
};

type Timeout = ReturnType<typeof setTimeout>;

export function SoundGroupPlayer(props: Props): JSX.Element {
  const audio = useRef<AudioControls>();
  const timeout = useRef<Timeout>();

  const sounds = props.soundGroup.sounds
    .slice()
    .sort(props.soundGroup.shuffle ? byShuffle(props.startedMs) : byName());

  useEffect(() => {
    if (sounds.length < 1) {
      return;
    }
    const timeMs = Math.max(0, Date.now() - props.startedMs);
    const { index, skipMs } = calcStart(timeMs);
    startAudio(index, skipMs);
    return stopAudio;
  }, [
    props.soundGroup.crossfadeMs,
    props.soundGroup.shuffle,
    JSON.stringify(props.soundGroup.sounds),
  ]);

  useEffect(() => {
    audio.current?.setMasterVolume(props.masterVolumePercent / 100);
  }, [props.masterVolumePercent]);

  function startAudio(index: number, skipMs: number) {
    console.log(Date.now(), "startAudio", "index", index, "skipMs", skipMs);

    const sound = sounds[index];

    // Start sound
    audio.current = FadingAudio({
      url: sound.audioUrl,
      startTime: (sound.cropStartMs + skipMs) / 1000,
      stopTime: (sound.lengthMs - sound.cropEndMs) / 1000,
      fadeTime: props.soundGroup.crossfadeMs / 1000,
      volume: sound.volumePercent / 100,
      masterVolume: props.masterVolumePercent / 100,
    });
    audio.current.start();

    // Queue next sound
    timeout.current = setTimeout(() => {
      index = (index + 1) % sounds.length;
      startAudio(index, 0);
    }, Math.max(1000, calcLength(sound) - skipMs));
  }

  function stopAudio() {
    console.log(Date.now(), "stopAudio");

    // Stop sound
    if (audio.current) {
      audio.current.stop();
      audio.current = undefined;
    }

    // Cancel queued sound
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
    }
  }

  function calcStart(timeMs: number) {
    let remainingMs = timeMs;
    let index = 0;
    while (true) {
      const sound = sounds[index];
      const length = Math.max(1000, calcLength(sound));
      if (remainingMs < length) {
        return { index: index, skipMs: remainingMs };
      }
      remainingMs = remainingMs - length;
      index = (index + 1) % sounds.length;
    }
  }

  function calcLength(sound: Sound) {
    return (
      sound.lengthMs -
      sound.cropEndMs -
      sound.cropStartMs -
      props.soundGroup.crossfadeMs
    );
  }

  return <></>;
}
