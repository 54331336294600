export function toHuman(bytes: number): string {
  const unit = ["B", "KB", "MB", "GB", "TB"];

  let i = 0;
  let n = bytes;
  while (n >= 1024) {
    n = n / 1024;
    i = i + 1;
  }

  const formatted = n > 100 ? n.toFixed() : n.toPrecision(3);

  return `${formatted} ${unit[i]}`;
}
