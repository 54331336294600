import * as React from "react";
import { Column, IconButton } from "@lundal/nyx-react";
import { activateVisual, moveVisual } from "../../api/ambianceApi";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { VisualGroupSection } from "./VisualGroupSection";
import { Dialog } from "./state";
import "./Visuals.scss";
import { Ambiance, Error } from "../../api/models";
import { byName } from "../../utils/SortUtils";

type Props = {
  ambiance: Ambiance;
  setDialog: (dialog: Dialog) => void;
  onSelected: () => void;
  onMoved: () => void;
};

export function Visuals(props: Props): JSX.Element {
  return (
    <div className="visuals">
      <Column>
        <div className="header">
          <span className="name">Visuals</span>
          <IconButton
            color="transparent"
            size="small"
            icon={faPlus}
            title="Create visual group"
            onClick={() =>
              props.setDialog({
                type: "CreateVisualGroup",
                ambianceId: props.ambiance.id,
              })
            }
          />
        </div>
        {props.ambiance.visualGroups
          .slice()
          .sort(byName())
          .map((group) => (
            <VisualGroupSection
              key={group.id}
              group={group}
              onEditGroup={() =>
                props.setDialog({
                  type: "EditVisualGroup",
                  ambianceId: props.ambiance.id,
                  group: group,
                })
              }
              onDeleteGroup={() =>
                props.setDialog({
                  type: "DeleteVisualGroup",
                  ambianceId: props.ambiance.id,
                  group: group,
                })
              }
              onAddVisual={() =>
                props.setDialog({
                  type: "CreateVisual",
                  ambianceId: props.ambiance.id,
                  group: group,
                })
              }
              onEditVisual={(visual) =>
                props.setDialog({
                  type: "EditVisual",
                  ambianceId: props.ambiance.id,
                  group: group,
                  visual: visual,
                })
              }
              onDeleteVisual={(visual) =>
                props.setDialog({
                  type: "DeleteVisual",
                  ambianceId: props.ambiance.id,
                  groupId: group.id,
                  visual: visual,
                })
              }
              onSelectVisual={(visual) => {
                activateVisual(props.ambiance.id, group.id, visual.id)
                  .then(() => {
                    props.onSelected();
                  })
                  .catch((error: Error) =>
                    // TODO: Improve
                    alert(error.reason)
                  );
              }}
              onMoveVisual={(id, groupId, position) => {
                moveVisual(props.ambiance.id, groupId, id, {
                  groupId: group.id,
                  position: position,
                })
                  .then(() => {
                    props.onMoved();
                  })
                  .catch((error: Error) =>
                    // TODO: Improve
                    alert(error.reason)
                  );
              }}
            />
          ))}
      </Column>
    </div>
  );
}
