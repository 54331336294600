import * as React from "react";
import { useEffect } from "react";
import { LoadingLayout } from "@lundal/nyx-react";
import { Config } from "../api/models";

type Props = {
  config: Config;
};

export function SignInPage(props: Props): JSX.Element {
  useEffect(() => {
    window.location.href =
      props.config.accountUrl +
      "/#/sign-in?return=" +
      encodeURIComponent(window.location.href);
  }, []);
  return <LoadingLayout />;
}
