import * as React from "react";
import { App, Header as NyxHeader } from "@lundal/nyx-react";

type Props = {
  apps?: App[];
  actions?: JSX.Element;
};

export function Header(props: Props): JSX.Element {
  return (
    <NyxHeader
      app={{ name: "Ambisphere", iconUrl: "/icon.svg", linkUrl: "/" }}
      apps={props.apps}
      actions={props.actions}
    />
  );
}
