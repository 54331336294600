interface Idd {
  id: string;
}

interface Named {
  name: string;
}

export function byId() {
  return (a: Idd, b: Idd) => a.id.localeCompare(b.id);
}

export function byName() {
  return (a: Named, b: Named) => a.name.localeCompare(b.name);
}

export function byShuffle(seed: number) {
  return (a: Idd, b: Idd) => hashcode(b.id, seed) - hashcode(a.id, seed);
}

// Based on https://stackoverflow.com/a/52171480
function hashcode(s: string, seed: number = 0): number {
  let h1 = 0xdeadbeef ^ seed;
  let h2 = 0x41c6ce57 ^ seed;
  for (let i = 0; i < s.length; i++) {
    let ch = s.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}
