import * as React from "react";
import { Active, Actives, Ambiance, SoundGroup } from "../../api/models";
import { SoundGroupPlayer } from "./SoundGroupPlayer";

type Props = {
  ambiance: Ambiance;
  actives: Actives;
  volume: number;
};

export function SoundGroupPlayers(props: Props): JSX.Element {
  return (
    <>
      {props.actives.soundGroups.map((active: Active) =>
        props.ambiance.soundGroups
          .filter((soundGroup: SoundGroup) => soundGroup.id == active.id)
          .map((soundGroup: SoundGroup) => (
            <SoundGroupPlayer
              key={active.id}
              soundGroup={soundGroup}
              startedMs={active.startedMs}
              masterVolumePercent={props.volume}
            />
          ))
      )}
    </>
  );
}
