import * as React from "react";
import { Column, IconButton } from "@lundal/nyx-react";
import { activateSoundGroup } from "../../api/ambianceApi";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "./state";
import "./Sounds.scss";
import { SoundGroupSection } from "./SoundGroupSection";
import { Ambiance, Error } from "../../api/models";
import { byName } from "../../utils/SortUtils";

type Props = {
  ambiance: Ambiance;
  setDialog: (dialog: Dialog) => void;
  onSelected: () => void;
};

export function Sounds(props: Props): JSX.Element {
  return (
    <div className="sounds">
      <Column>
        <div className="header">
          <span className="name">Sounds</span>
          <IconButton
            color="transparent"
            size="small"
            icon={faPlus}
            title="Create sound group"
            onClick={() =>
              props.setDialog({
                type: "CreateSoundGroup",
                ambianceId: props.ambiance.id,
              })
            }
          />
        </div>
        {props.ambiance.soundGroups
          .slice()
          .sort(byName())
          .map((group) => (
            <SoundGroupSection
              key={group.id}
              group={group}
              onEditGroup={() =>
                props.setDialog({
                  type: "EditSoundGroup",
                  ambianceId: props.ambiance.id,
                  group: group,
                })
              }
              onDeleteGroup={() =>
                props.setDialog({
                  type: "DeleteSoundGroup",
                  ambianceId: props.ambiance.id,
                  group: group,
                })
              }
              onSelectGroup={() => {
                activateSoundGroup(props.ambiance.id, group.id)
                  .then(() => {
                    props.onSelected();
                  })
                  .catch((error: Error) =>
                    // TODO: Improve
                    alert(error.reason)
                  );
              }}
              onAddSound={() =>
                props.setDialog({
                  type: "CreateSound",
                  ambianceId: props.ambiance.id,
                  group: group,
                })
              }
              onEditSound={(sound) =>
                props.setDialog({
                  type: "EditSound",
                  ambianceId: props.ambiance.id,
                  group: group,
                  sound: sound,
                })
              }
              onDeleteSound={(sound) =>
                props.setDialog({
                  type: "DeleteSound",
                  ambianceId: props.ambiance.id,
                  groupId: group.id,
                  sound: sound,
                })
              }
            />
          ))}
      </Column>
    </div>
  );
}
