import * as React from "react";

type Props = {
  onSubmit: () => void;
  children: React.ReactNode;
};

export function Form(props: Props): JSX.Element {
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        props.onSubmit();
      }}
    >
      {props.children}
    </form>
  );
}
