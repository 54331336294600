import * as React from "react";
import {
  Button,
  Column,
  Dialog,
  Heading,
  Input,
  Message,
  Row,
  TextBox,
} from "@lundal/nyx-react";
import { Form } from "../../components/Form";
import { updateAmbiance } from "../../api/ambianceApi";
import { Ambiance, Error } from "../../api/models";
import { useForm } from "../../hooks/useForm";
import { validateName } from "../../common/Validators";

type Fields = {
  name: string;
};

type Errors = {
  name?: string;
};

type Props = {
  ambiance: Ambiance;
  onUpdated: () => void;
  onClose: () => void;
};

export function EditAmbianceDialog(props: Props): JSX.Element {
  const form = useForm<Fields, Errors, Ambiance, Error>(
    { name: props.ambiance.name },
    (fields) => ({ name: validateName(fields.name) }),
    (fields) => updateAmbiance(props.ambiance.id, { name: fields.name }),
    () => {
      props.onUpdated();
      props.onClose();
    }
  );

  return (
    <Dialog onDismiss={() => props.onClose()}>
      <Form onSubmit={form.submit}>
        <Column>
          <Heading level={2}>Edit Ambiance</Heading>
          <Input
            id="name"
            label="Name"
            help="The name of your ambiance"
            error={form.errors.name}
            input={
              <TextBox
                value={form.fields.name}
                onChange={(value) => form.update({ name: value })}
              />
            }
          />
          <Row>
            <Button
              color={form.valid ? "white" : undefined}
              label="Save ambiance"
            />
            <Button label="Cancel" onClick={() => props.onClose()} />
          </Row>
          {form.state.type == "Failure" && (
            <Message type="error" text={form.state.error.reason} />
          )}
        </Column>
      </Form>
    </Dialog>
  );
}
