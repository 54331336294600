import * as React from "react";
import { IconButton } from "@lundal/nyx-react";
import { Visual, VisualGroup } from "../../api/models";
import "./VisualThumbnail.scss";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { VisualView } from "../ViewAmbiance/VisualView";
import { useRef, useState } from "react";

type Props = {
  group: VisualGroup;
  visual: Visual;
  position: number;
  onEdit: () => void;
  onDelete: () => void;
  onSelect: () => void;
  onMoveVisual: (id: string, groupId: string, position: number) => void;
};

export function VisualThumbnail(props: Props): JSX.Element {
  const [drop, setDrop] = useState<boolean>(false);
  const buttonElement = useRef<HTMLButtonElement>(null);

  return (
    <button
      ref={buttonElement}
      className="visual-thumbnail"
      onClick={props.onSelect}
      draggable={true}
      onDragStart={(event) => {
        event.dataTransfer.setData(
          "application/x-visual",
          JSON.stringify({
            groupId: props.group.id,
            id: props.visual.id,
          })
        );
        // Data is not available in onDragEnter and onDragLeave,
        // so we store the visual id as a data type to access it
        event.dataTransfer.setData("x-visual-id:" + props.visual.id, "");
      }}
      onDrop={(event) => {
        const data = event.dataTransfer.getData("application/x-visual");
        const { groupId, id } = JSON.parse(data);

        event.preventDefault();
        setDrop(false);

        props.onMoveVisual(id, groupId, props.position);
      }}
      onDragEnter={(event) => {
        if (buttonElement.current!.contains(event.relatedTarget as Node)) {
          // Mouse moved to a different child element
          return;
        }

        if (!event.dataTransfer.types.includes("application/x-visual")) {
          // Unrecognized event
          return;
        }

        if (
          event.dataTransfer.types.includes("x-visual-id:" + props.visual.id)
        ) {
          // Must move to a different position
          return;
        }

        event.preventDefault();
        setDrop(true);
      }}
      onDragLeave={(event) => {
        if (buttonElement.current!.contains(event.relatedTarget as Node)) {
          // Mouse moved to a different child element
          return;
        }

        event.preventDefault();
        setDrop(false);
      }}
      onDragOver={(event) => {
        event.preventDefault();
      }}
    >
      <VisualView
        backgroundColor={props.group.backgroundColor}
        containImage={props.group.containImage}
        crossfadeMs={props.group.crossfadeMs}
        imageUrl={props.visual.thumbnailUrl}
      />
      <div className="name">{props.visual.name}</div>
      <div className="edit">
        <IconButton
          size="small"
          icon={faPencilAlt}
          title={`Edit '${props.visual.name}'`}
          onClick={props.onEdit}
        />
      </div>
      <div className="delete">
        <IconButton
          size="small"
          icon={faTrashAlt}
          title={`Delete '${props.visual.name}'`}
          onClick={props.onDelete}
        />
      </div>
      {drop && <div className="drop-highlight" />}
    </button>
  );
}
