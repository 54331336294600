export type Fields = {
  name: string;
  backgroundColor: string;
  containImage: boolean;
  crossfadeMs: string;
};

export type Errors = {
  name?: string;
  backgroundColor?: string;
  crossfadeMs?: string;
};

export const containImageOptions = [
  { value: false, label: "Cover entire view" },
  { value: true, label: "Show entire image" },
];
