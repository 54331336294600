import * as React from "react";
import { useState } from "react";
import {
  Button,
  Column,
  Dialog,
  FilePicker,
  Heading,
  Input,
  Message,
  Row,
  Spinner,
  TextBox,
} from "@lundal/nyx-react";
import { Errors, Fields } from "./types";
import { Form } from "../../components/Form";
import { createSound } from "../../api/ambianceApi";
import { Error, Sound, SoundGroup } from "../../api/models";
import { toDataUrl, toFile } from "../../utils/FileUtils";
import { uploadFile } from "../../api/fileApi";
import { useForm } from "../../hooks/useForm";
import {
  validateCrop,
  validateName,
  validateVolume,
} from "../../common/Validators";

type Props = {
  ambianceId: string;
  group: SoundGroup;
  onCreated: () => void;
  onClose: () => void;
};

export function CreateSoundDialog(props: Props): JSX.Element {
  const form = useForm<Fields, Errors, Sound, Error>(
    {
      name: "",
      cropStartMs: "0",
      cropEndMs: "0",
      lengthMs: 0,
      volumePercent: "100",
      audioUrl: "",
    },
    (fields) => {
      const maxCrop = Math.floor(fields.lengthMs / 2);
      return {
        name: validateName(fields.name),
        cropStartMs: validateCrop(maxCrop)(fields.cropStartMs),
        cropEndMs: validateCrop(maxCrop)(fields.cropEndMs),
        volumePercent: validateVolume(fields.volumePercent),
        audioUrl: undefined,
      };
    },
    (fields) =>
      createSound(props.ambianceId, props.group.id, {
        name: fields.name,
        cropStartMs: parseInt(fields.cropStartMs),
        cropEndMs: parseInt(fields.cropEndMs),
        lengthMs: fields.lengthMs,
        volumePercent: parseInt(fields.volumePercent),
        audioUrl: fields.lengthMs > 0 ? fields.audioUrl : "",
      }),
    () => {
      props.onCreated();
      props.onClose();
    }
  );
  const [processing, setProcessing] = useState(false);

  function onFilesChanged(files: File[]): void {
    if (files.length < 1) {
      return;
    }
    if (files[0].size > 50 * 1024 * 1024) {
      return;
    }
    setProcessing(true);
    toDataUrl(files[0])
      .then(async (dataUrl) => {
        const audio = await uploadFile(toFile(dataUrl), "audio");
        form.update({
          lengthMs: 0,
          audioUrl: audio.url,
        });
        setProcessing(false);
      })
      .catch((error: Error) => {
        form.update({
          lengthMs: 0,
          audioUrl: "",
        });
        setProcessing(false);
        // TODO: Improve
        alert(error.reason);
      });
  }

  function onDurationChanged(e: React.SyntheticEvent<HTMLAudioElement>) {
    const lengthMs = Math.floor(e.currentTarget.duration * 1000);
    if (lengthMs != Infinity && form.fields.audioUrl) {
      form.update({
        lengthMs: lengthMs,
      });
    }
  }

  return (
    <Dialog onDismiss={() => props.onClose()}>
      <Form onSubmit={form.submit}>
        <Column>
          <Heading level={2}>Create Sound</Heading>
          <Input
            id="name"
            label="Name"
            help="A name for the sound"
            error={form.errors.name}
            input={
              <TextBox
                value={form.fields.name}
                onChange={(value) => form.update({ name: value })}
              />
            }
          />
          <Input
            id="url"
            label="Url"
            help="The url to the sound"
            error={form.errors.audioUrl}
            input={
              <TextBox
                value={form.fields.audioUrl}
                onChange={(value) =>
                  form.update({ audioUrl: value, lengthMs: 0 })
                }
              />
            }
          />
          <Input
            help="Supports mp3, wav, ogg and opus (max 50 MB)"
            error={undefined}
            input={
              <Row align="center">
                <FilePicker
                  label="Select audio"
                  accept="audio/mpeg,audio/wav,audio/ogg,audio/opus"
                  onChange={onFilesChanged}
                />
                {processing && <Spinner />}
              </Row>
            }
          />
          <Input
            id="crop-start"
            label="Crop start"
            help="Duration to crop (in milliseconds)"
            error={form.errors.cropStartMs}
            input={
              <TextBox
                value={form.fields.cropStartMs}
                onChange={(value) => form.update({ cropStartMs: value })}
              />
            }
          />
          <Input
            id="crop-end"
            label="Crop end"
            help="Duration to crop (in milliseconds)"
            error={form.errors.cropEndMs}
            input={
              <TextBox
                value={form.fields.cropEndMs}
                onChange={(value) => form.update({ cropEndMs: value })}
              />
            }
          />
          <Input
            id="volume"
            label="Volume"
            help="Volume (in percent)"
            error={form.errors.volumePercent}
            input={
              <TextBox
                value={form.fields.volumePercent}
                onChange={(value) => form.update({ volumePercent: value })}
              />
            }
          />
          <div>
            <audio
              controls
              src={form.fields.audioUrl}
              onDurationChange={onDurationChanged}
              preload="auto"
            />
          </div>
          <div />
          <Row align="center">
            <Button
              color={form.valid ? "white" : undefined}
              label="Create sound"
            />
            <Button label="Cancel" onClick={() => props.onClose()} />
          </Row>
          {form.state.type == "Failure" && (
            <Message type="error" text={form.state.error.reason} />
          )}
        </Column>
      </Form>
    </Dialog>
  );
}
