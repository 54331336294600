import * as React from "react";
import { NavLink } from "react-router-dom";
import { faImage, faMusic } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AmbianceSummary } from "../../api/models";
import "./AmbianceLink.scss";

type Props = {
  ambiance: AmbianceSummary;
};

export function AmbianceLink(props: Props): JSX.Element {
  return (
    <NavLink
      className="ambiance-link"
      to={"/ambiance/" + props.ambiance.id + "/edit"}
    >
      <span title={props.ambiance.name}>{props.ambiance.name}</span>
      <span title={"Visuals: " + props.ambiance.visuals}>
        <FontAwesomeIcon className="icon" icon={faImage} />
        {props.ambiance.visuals}
      </span>
      <span title={"Sounds: " + props.ambiance.sounds}>
        <FontAwesomeIcon className="icon" icon={faMusic} />
        {props.ambiance.sounds}
      </span>
    </NavLink>
  );
}
