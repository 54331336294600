import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { faVolumeOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKeyDown } from "../../hooks/useKeyDown";
import "./VolumeControl.scss";

type Props = {
  volume: number;
  setVolume: (volume: number) => void;
};

type Timeout = ReturnType<typeof setTimeout>;

export function VolumeControl(props: Props): JSX.Element {
  const [visible, setVisible] = useState<boolean>(false);
  const visibleTimeout = useRef<Timeout>();

  useEffect(makeVisible, []);

  useKeyDown(function (e) {
    const volumeChange = volumeChangeForKey(e);
    if (volumeChange == 0) {
      return;
    }
    props.setVolume(contain(props.volume + volumeChange, [0, 100]));
    makeVisible();
  });

  function makeVisible() {
    if (visibleTimeout.current) {
      clearTimeout(visibleTimeout.current);
    }
    setVisible(true);
    visibleTimeout.current = setTimeout(() => setVisible(false), 3000);
  }

  return (
    <div className={"volume-control " + (visible ? "" : "hidden")}>
      <FontAwesomeIcon className="icon" icon={faVolumeOff} />
      {props.volume}
    </div>
  );
}

function volumeChangeForKey(e: KeyboardEvent): number {
  if (e.key == "+" || e.key == "ArrowUp") {
    return 5;
  }
  if (e.key == "-" || e.key == "ArrowDown") {
    return -5;
  }
  return 0;
}

function contain(value: number, range: [number, number]): number {
  return Math.max(range[0], Math.min(range[1], value));
}
