import { useState } from "react";

export function useForm<Fields, Errors, Success, Failure>(
  initial: Fields,
  validator: (fields: Fields) => Errors,
  submitter: (fields: Fields) => Promise<Success>,
  onSuccess: (value: Success) => void = () => {},
  onFailure: (error: Failure) => void = () => {}
): {
  fields: Fields;
  errors: Partial<Errors>;
  invalid: boolean;
  valid: boolean;
  state: State<Success, Failure>;
  update: (fields: Partial<Fields>) => void;
  submit: () => void;
} {
  // Create state
  const [fields, setFields] = useState<Fields>(initial);
  const [state, setState] = useState<State<Success, Failure>>({
    type: "HideErrors",
  });

  // Inferred state
  const errors = validator(fields);
  const invalid = containsTruthy(errors);

  // Return state and modifiers
  return {
    fields: fields,
    errors: state.type === "ShowErrors" ? errors : {},
    invalid: invalid,
    valid: !invalid,
    state: state,
    update: (fields) => {
      setFields((prevFields) => ({ ...prevFields, ...fields }));
    },
    submit: () => {
      // Prevent concurrent submits
      if (state.type === "Submitting") {
        return;
      }
      // Show errors if any
      if (invalid) {
        setState({ type: "ShowErrors" });
        return;
      }
      // Submit
      setState({ type: "Submitting" });
      submitter(fields)
        .then((value: Success) => {
          setState({ type: "Success", value: value });
          onSuccess(value);
        })
        .catch((error: Failure) => {
          setState({ type: "Failure", error: error });
          onFailure(error);
        });
    },
  };
}

function containsTruthy<T>(value: T): boolean {
  if (!value) {
    return false;
  }
  if (typeof value === "object") {
    if (Array.isArray(value)) {
      return value.some(containsTruthy);
    } else {
      return Object.values(value).some(containsTruthy);
    }
  }
  return true;
}

type State<Value, Error> =
  | HideErrors
  | ShowErrors
  | Submitting
  | Success<Value>
  | Failure<Error>;

type HideErrors = {
  type: "HideErrors";
};

type ShowErrors = {
  type: "ShowErrors";
};

type Submitting = {
  type: "Submitting";
};

type Success<Value> = {
  type: "Success";
  value: Value;
};

type Failure<Error> = {
  type: "Failure";
  error: Error;
};
