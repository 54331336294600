import {
  int,
  length,
  regex,
  required,
  validator,
} from "../utils/ValidationUtils";

export const validateName = validator([
  [required(), "Name is required"],
  [length(1, 64), "Name must be between 1 and 64 characters"],
]);

export const validateColor = validator([
  [required(), "Color is required"],
  [
    regex(/^([A-Za-z]{3,64}|#[0-9A-Fa-f]{6})$/),
    "Color must be a name (red) or hex value (#FF0000)",
  ],
]);

export const validateCrossfade = validator([
  [required(), "Crossfade is required"],
  [int(0, 10000), "Crossfade must be a number between 0 and 10000"],
]);

export const validateCrop = (max: number) =>
  validator([
    [required(), "Crop is required"],
    [int(0, max), `Crop must be a number between 0 and ${max}`],
  ]);

export const validateVolume = validator([
  [required(), "Volume is required"],
  [int(0, 100), "Volume must be a number between 0 and 100"],
]);
