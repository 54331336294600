import * as React from "react";
import "./ViewLayout.scss";

type Props = {
  children: React.ReactNode;
};

export function ViewLayout(props: Props): JSX.Element {
  return <div className="view-layout">{props.children}</div>;
}
