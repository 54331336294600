import * as React from "react";
import { VisualGroup } from "../../api/models";
import "./AddVisual.scss";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  group: VisualGroup;
  position: number;
  onAddVisual: () => void;
  onMoveVisual: (id: string, groupId: string, position: number) => void;
};

export function AddVisual(props: Props): JSX.Element {
  const [drop, setDrop] = useState<boolean>(false);
  const buttonElement = useRef<HTMLButtonElement>(null);

  return (
    <button
      ref={buttonElement}
      className="add-visual"
      title={`Create visual in '${props.group.name}'`}
      onClick={props.onAddVisual}
      onDrop={(event) => {
        const data = event.dataTransfer.getData("application/x-visual");
        const { groupId, id } = JSON.parse(data);

        event.preventDefault();
        setDrop(false);

        props.onMoveVisual(id, groupId, props.position);
      }}
      onDragEnter={(event) => {
        if (buttonElement.current!.contains(event.relatedTarget as Node)) {
          // Mouse moved to a different child element
          return;
        }

        if (!event.dataTransfer.types.includes("application/x-visual")) {
          // Unrecognized event
          return;
        }

        event.preventDefault();
        setDrop(true);
      }}
      onDragLeave={(event) => {
        if (buttonElement.current!.contains(event.relatedTarget as Node)) {
          // Mouse moved to a different child element
          return;
        }

        event.preventDefault();
        setDrop(false);
      }}
      onDragOver={(event) => {
        event.preventDefault();
      }}
    >
      <FontAwesomeIcon icon={faPlus} />
      {drop && <div className="drop-highlight" />}
    </button>
  );
}
