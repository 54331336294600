import * as React from "react";
import { useState } from "react";
import {
  Button,
  Column,
  Dialog,
  FilePicker,
  Heading,
  Input,
  Message,
  Row,
  Spinner,
  TextBox,
} from "@lundal/nyx-react";
import { Errors, Fields } from "./types";
import { Form } from "../../components/Form";
import { createVisual } from "../../api/ambianceApi";
import { Error, Visual, VisualGroup } from "../../api/models";
import { VisualView } from "../../pages/ViewAmbiance/VisualView";
import { toDataUrl, toFile } from "../../utils/FileUtils";
import { uploadFile } from "../../api/fileApi";
import { useForm } from "../../hooks/useForm";
import { validateName } from "../../common/Validators";

type Props = {
  ambianceId: string;
  group: VisualGroup;
  onCreated: () => void;
  onClose: () => void;
};

export function CreateVisualDialog(props: Props): JSX.Element {
  const form = useForm<Fields, Errors, Visual, Error>(
    {
      name: "",
      thumbnailUrl: "",
      imageUrl: "",
    },
    (fields) => ({
      name: validateName(fields.name),
    }),
    (fields) =>
      createVisual(props.ambianceId, props.group.id, {
        name: fields.name,
        thumbnailUrl: fields.thumbnailUrl,
        imageUrl: fields.imageUrl,
      }),
    () => {
      props.onCreated();
      props.onClose();
    }
  );
  const [processing, setProcessing] = useState(false);

  function onFilesChanged(files: File[]): void {
    if (files.length < 1) {
      return;
    }
    if (files[0].size > 50 * 1024 * 1024) {
      return;
    }
    // TODO: Convert in browser if Chromimum?
    // Firefox:
    // - https://bugzilla.mozilla.org/show_bug.cgi?id=1750475
    // - https://bugzilla.mozilla.org/show_bug.cgi?id=1228847
    //
    // createImageBitmap(files[0])
    //   .then(async (bitmap) => {
    //     const thumbnail = await uploadFile(scaleImage(bitmap, 512 * 512, 0.4));
    //     const image = await uploadFile(scaleImage(bitmap, 2048 * 2048, 0.6));
    //     return [thumbnail, image];
    //   })
    setProcessing(true);
    toDataUrl(files[0])
      .then(async (dataUrl) => {
        const thumbnail = await uploadFile(toFile(dataUrl), "thumbnail");
        const image = await uploadFile(toFile(dataUrl), "image");
        form.update({
          thumbnailUrl: thumbnail.url,
          imageUrl: image.url,
        });
        setProcessing(false);
      })
      .catch((error: Error) => {
        form.update({
          thumbnailUrl: "",
          imageUrl: "",
        });
        setProcessing(false);
        // TODO: Improve
        alert(error.reason);
      });
  }

  return (
    <Dialog onDismiss={() => props.onClose()}>
      <Form onSubmit={form.submit}>
        <Column>
          <Heading level={2}>Create Visual</Heading>
          <Input
            id="name"
            label="Name"
            help="A name for the visual"
            error={form.errors.name}
            input={
              <TextBox
                value={form.fields.name}
                onChange={(value) => form.update({ name: value })}
              />
            }
          />
          <Input
            help="Supports jpeg, png, webp and avif (max 50 MB)"
            error={undefined}
            input={
              <Row align="center">
                <FilePicker
                  label="Select image"
                  accept="image/jpeg,image/png,image/webp,image/avif"
                  onChange={onFilesChanged}
                />
                {processing && <Spinner />}
              </Row>
            }
          />
          <div
            style={{
              position: "relative",
              width: "100%",
              paddingBottom: "56.25%",
              overflow: "hidden",
              borderRadius: "var(--size-1)",
              background: "black",
            }}
          >
            <VisualView
              backgroundColor={props.group.backgroundColor}
              containImage={props.group.containImage}
              crossfadeMs={props.group.crossfadeMs}
              imageUrl={form.fields.thumbnailUrl}
            />
          </div>
          <div />
          <Row align="center">
            <Button
              color={form.valid ? "white" : undefined}
              label="Create visual"
            />
            <Button label="Cancel" onClick={() => props.onClose()} />
          </Row>
          {form.state.type == "Failure" && (
            <Message type="error" text={form.state.error.reason} />
          )}
        </Column>
      </Form>
    </Dialog>
  );
}
