import * as React from "react";
import { IconButton } from "@lundal/nyx-react";
import { faMusic, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deactivateSoundGroup } from "../../api/ambianceApi";
import { byName } from "../../utils/SortUtils";
import "./SoundControls.scss";
import { Actives, Ambiance, Error, SoundGroup } from "../../api/models";

type Props = {
  ambiance: Ambiance;
  actives: Actives;
  onChange: () => void;
};

export function SoundControls(props: Props): JSX.Element {
  function isActive(soundGroup: SoundGroup): boolean {
    return props.actives.soundGroups.some(
      (active) => active.id == soundGroup.id
    );
  }

  return (
    <div className="sound-controls">
      {props.ambiance.soundGroups
        .filter(isActive)
        .sort(byName())
        .map((soundGroup) => (
          <div className="sound">
            <FontAwesomeIcon className="icon" icon={faMusic} />
            <span className="name">{soundGroup.name}</span>
            <IconButton
              color="transparent"
              size="small"
              icon={faTimes}
              title={`Stop '${soundGroup.name}'`}
              onClick={() =>
                deactivateSoundGroup(props.ambiance.id, soundGroup.id)
                  .then(() => props.onChange())
                  .catch((error: Error) =>
                    // TODO: Improve
                    alert(error.reason)
                  )
              }
            />
          </div>
        ))}
    </div>
  );
}
