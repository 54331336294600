import { timestamp } from "./TimeUtils";

export type AudioProps = {
  url: string;
  startTime: number;
  stopTime: number;
  fadeTime: number;
  volume: number;
  masterVolume: number;
};

export type AudioControls = {
  start: () => void;
  stop: () => void;
  setMasterVolume: (volume: number) => void;
};

export function FadingAudio(props: AudioProps): AudioControls {
  let audio: HTMLAudioElement | undefined = new Audio();
  audio.src = props.url;
  audio.currentTime = props.startTime;
  audio.volume = 0;

  function calcFadedVolume(time: number): number {
    // Outside [start, stop]
    if (time < props.startTime || time > props.stopTime) {
      return 0;
    }
    let volume = 1;
    // Fade in
    if (time < props.startTime + props.fadeTime) {
      volume = Math.min(volume, (time - props.startTime) / props.fadeTime);
    }
    // Fade out
    if (time > props.stopTime - props.fadeTime) {
      volume = Math.min(volume, (props.stopTime - time) / props.fadeTime);
    }
    return volume;
  }

  function start() {
    const startTime = timestamp();
    audio!
      .play()
      .then(() => {
        const interval = setInterval(() => {
          if (!audio) {
            return clearInterval(interval);
          }

          // Update time
          const targetTime = props.startTime + startTime.secondsSince();
          if (Math.abs(targetTime - audio.currentTime) > 0.2) {
            audio.currentTime = targetTime + 0.1;
          }

          // Update volume
          const targetVolume =
            calcFadedVolume(targetTime) * props.volume * props.masterVolume;
          if (Math.abs(targetVolume - audio.volume) > 0.01) {
            audio.volume = targetVolume;
          }

          // Check if done
          if (targetTime > props.stopTime) {
            audio.pause();
            audio = undefined;
          }
        }, 10);
      })
      .catch((err) => {
        console.log("Could not play audio", err);
        audio = undefined;
      });
  }

  function stop() {
    if (!audio) {
      return;
    }
    props.stopTime = Math.min(
      props.stopTime,
      audio.currentTime + props.fadeTime
    );
  }

  function setMasterVolume(volume: number) {
    props.masterVolume = volume;
  }

  return { start, stop, setMasterVolume };
}
