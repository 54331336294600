import * as React from "react";
import { useState } from "react";
import { IconButton } from "@lundal/nyx-react";
import { Sound, SoundGroup } from "../../api/models";
import {
  faChevronDown,
  faChevronRight,
  faMusic,
  faPencilAlt,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./SoundGroupSection.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byName } from "../../utils/SortUtils";

type Props = {
  group: SoundGroup;
  onEditGroup: () => void;
  onDeleteGroup: () => void;
  onSelectGroup: () => void;
  onAddSound: () => void;
  onEditSound: (sound: Sound) => void;
  onDeleteSound: (sound: Sound) => void;
};

export function SoundGroupSection(props: Props): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="sound-group">
      <div className="header">
        <IconButton
          color="transparent"
          size="small"
          icon={expanded ? faChevronDown : faChevronRight}
          title={expanded ? "Collapse" : "Expand"}
          onClick={() => setExpanded(!expanded)}
        />
        <button className="name" onClick={props.onSelectGroup}>
          {props.group.name}
        </button>
        <IconButton
          color="transparent"
          size="small"
          icon={faPencilAlt}
          title={`Edit '${props.group.name}'`}
          onClick={props.onEditGroup}
        />
        <IconButton
          color="transparent"
          size="small"
          icon={faTrashAlt}
          title={`Delete '${props.group.name}'`}
          onClick={props.onDeleteGroup}
        />
      </div>
      {expanded && (
        <div className="body">
          {props.group.sounds
            .slice()
            .sort(byName())
            .map((sound) => (
              <div key={sound.id} className="sound">
                <FontAwesomeIcon className="icon" icon={faMusic} />
                <span className="name">{sound.name}</span>
                <IconButton
                  color="transparent"
                  size="small"
                  icon={faPencilAlt}
                  title={`Edit '${sound.name}'`}
                  onClick={() => props.onEditSound(sound)}
                />
                <IconButton
                  color="transparent"
                  size="small"
                  icon={faTrashAlt}
                  title={`Delete '${sound.name}'`}
                  onClick={() => props.onDeleteSound(sound)}
                />
              </div>
            ))}
          <button className="sound add" onClick={props.onAddSound}>
            <FontAwesomeIcon className="icon" icon={faPlus} />
            <span className="name">Add sound</span>
          </button>
        </div>
      )}
    </div>
  );
}
