import * as React from "react";
import "./EditLayout.scss";

type Props = {
  children: React.ReactNode;
};

export function EditLayout(props: Props): JSX.Element {
  return <div className="edit-layout">{props.children}</div>;
}
