import * as React from "react";
import {
  Button,
  Column,
  Dialog,
  Heading,
  Input,
  Message,
  RadioGroup,
  Row,
  TextBox,
} from "@lundal/nyx-react";
import { containImageOptions, Errors, Fields } from "./types";
import { Form } from "../../components/Form";
import { createVisualGroup } from "../../api/ambianceApi";
import { Error, VisualGroup } from "../../api/models";
import { useForm } from "../../hooks/useForm";
import {
  validateColor,
  validateCrossfade,
  validateName,
} from "../../common/Validators";

type Props = {
  ambianceId: string;
  onCreated: () => void;
  onClose: () => void;
};

export function CreateVisualGroupDialog(props: Props): JSX.Element {
  const form = useForm<Fields, Errors, VisualGroup, Error>(
    {
      name: "",
      backgroundColor: "black",
      containImage: true,
      crossfadeMs: "1000",
    },
    (fields) => ({
      name: validateName(fields.name),
      backgroundColor: validateColor(fields.backgroundColor),
      crossfadeMs: validateCrossfade(fields.crossfadeMs),
    }),
    (fields) =>
      createVisualGroup(props.ambianceId, {
        name: fields.name,
        backgroundColor: fields.backgroundColor,
        containImage: fields.containImage,
        crossfadeMs: parseInt(fields.crossfadeMs),
      }),
    () => {
      props.onCreated();
      props.onClose();
    }
  );

  return (
    <Dialog onDismiss={() => props.onClose()}>
      <Form onSubmit={form.submit}>
        <Column>
          <Heading level={2}>Create Visual Group</Heading>
          <Input
            id="name"
            label="Name"
            help="A name for the visual group"
            error={form.errors.name}
            input={
              <TextBox
                value={form.fields.name}
                onChange={(value) => form.update({ name: value })}
              />
            }
          />
          <Input
            id="color"
            label="Background color"
            help="The background for visuals with transparent areas"
            error={form.errors.backgroundColor}
            input={
              <TextBox
                value={form.fields.backgroundColor}
                onChange={(value) => form.update({ backgroundColor: value })}
              />
            }
          />
          <Input
            id="crossfade"
            label="Crossfade"
            help="The duration for visuals to fade in and out (in milliseconds)"
            error={form.errors.crossfadeMs}
            input={
              <TextBox
                value={form.fields.crossfadeMs}
                onChange={(value) => form.update({ crossfadeMs: value })}
              />
            }
          />
          <Input
            id="contain"
            label="Image size"
            input={
              <RadioGroup
                options={containImageOptions}
                value={form.fields.containImage}
                onChange={(value) => form.update({ containImage: value })}
              />
            }
          />
          <div />
          <Row>
            <Button
              color={form.valid ? "white" : undefined}
              label="Create visual group"
            />
            <Button label="Cancel" onClick={() => props.onClose()} />
          </Row>
          {form.state.type == "Failure" && (
            <Message type="error" text={form.state.error.reason} />
          )}
        </Column>
      </Form>
    </Dialog>
  );
}
