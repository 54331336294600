import * as React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Heading, IconButton, Row } from "@lundal/nyx-react";
import { Header } from "../../components/Header";
import { Actives, Ambiance, Config, Error } from "../../api/models";
import { deleteActives, getActives, getAmbiance } from "../../api/ambianceApi";
import { EditLayout } from "../../components/EditLayout";
import { CreateVisualGroupDialog } from "../../dialogs/VisualGroup/create";
import { EditVisualGroupDialog } from "../../dialogs/VisualGroup/edit";
import { Dialog } from "./state";
import { CreateVisualDialog } from "../../dialogs/Visual/create";
import { EditVisualDialog } from "../../dialogs/Visual/edit";
import { DeleteVisualGroupDialog } from "../../dialogs/VisualGroup/delete";
import { DeleteVisualDialog } from "../../dialogs/Visual/delete";
import { Visuals } from "./Visuals";
import { VisualViews } from "../ViewAmbiance/VisualViews";
import {
  faExternalLinkAlt,
  faPencilAlt,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { DeleteAmbianceDialog } from "../../dialogs/Ambiance/delete";
import { EditAmbianceDialog } from "../../dialogs/Ambiance/edit";
import { Sounds } from "./Sounds";
import { CreateSoundGroupDialog } from "../../dialogs/SoundGroup/create";
import { EditSoundGroupDialog } from "../../dialogs/SoundGroup/edit";
import { DeleteSoundGroupDialog } from "../../dialogs/SoundGroup/delete";
import { CreateSoundDialog } from "../../dialogs/Sound/create";
import { DeleteSoundDialog } from "../../dialogs/Sound/delete";
import { EditSoundDialog } from "../../dialogs/Sound/edit";
import { SoundControls } from "./SoundControls";
import { useResource } from "../../hooks/useResource";

type Params = {
  id: string;
};

type Props = {
  config: Config;
};

export function EditAmbiancePage(props: Props): JSX.Element {
  const params = useParams<Params>();
  const [ambiance, refreshAmbiance] = useResource<Ambiance, Error>(
    () => getAmbiance(params.id),
    [params.id]
  );
  const [actives, refreshActives] = useResource<Actives, Error>(
    () => getActives(params.id),
    [params.id]
  );
  const [dialog, setDialog] = useState<Dialog>({ type: "None" });

  function renderDialog(): JSX.Element | null {
    switch (dialog.type) {
      case "CreateSoundGroup":
        return (
          <CreateSoundGroupDialog
            ambianceId={dialog.ambianceId}
            onCreated={() => refreshAmbiance()}
            onClose={() => setDialog({ type: "None" })}
          />
        );
      case "EditSoundGroup":
        return (
          <EditSoundGroupDialog
            ambianceId={dialog.ambianceId}
            group={dialog.group}
            onUpdated={() => refreshAmbiance()}
            onClose={() => setDialog({ type: "None" })}
          />
        );
      case "DeleteSoundGroup":
        return (
          <DeleteSoundGroupDialog
            ambianceId={dialog.ambianceId}
            group={dialog.group}
            onDeleted={() => refreshAmbiance()}
            onClose={() => setDialog({ type: "None" })}
          />
        );

      case "CreateSound":
        return (
          <CreateSoundDialog
            ambianceId={dialog.ambianceId}
            group={dialog.group}
            onCreated={() => refreshAmbiance()}
            onClose={() => setDialog({ type: "None" })}
          />
        );
      case "EditSound":
        return (
          <EditSoundDialog
            ambianceId={dialog.ambianceId}
            group={dialog.group}
            sound={dialog.sound}
            onUpdated={() => refreshAmbiance()}
            onClose={() => setDialog({ type: "None" })}
          />
        );
      case "DeleteSound":
        return (
          <DeleteSoundDialog
            ambianceId={dialog.ambianceId}
            groupId={dialog.groupId}
            sound={dialog.sound}
            onDeleted={() => refreshAmbiance()}
            onClose={() => setDialog({ type: "None" })}
          />
        );
      case "CreateVisualGroup":
        return (
          <CreateVisualGroupDialog
            ambianceId={dialog.ambianceId}
            onCreated={() => refreshAmbiance()}
            onClose={() => setDialog({ type: "None" })}
          />
        );
      case "EditVisualGroup":
        return (
          <EditVisualGroupDialog
            ambianceId={dialog.ambianceId}
            group={dialog.group}
            onUpdated={() => refreshAmbiance()}
            onClose={() => setDialog({ type: "None" })}
          />
        );
      case "DeleteVisualGroup":
        return (
          <DeleteVisualGroupDialog
            ambianceId={dialog.ambianceId}
            group={dialog.group}
            onDeleted={() => refreshAmbiance()}
            onClose={() => setDialog({ type: "None" })}
          />
        );
      case "CreateVisual":
        return (
          <CreateVisualDialog
            ambianceId={dialog.ambianceId}
            group={dialog.group}
            onCreated={() => refreshAmbiance()}
            onClose={() => setDialog({ type: "None" })}
          />
        );
      case "EditVisual":
        return (
          <EditVisualDialog
            ambianceId={dialog.ambianceId}
            group={dialog.group}
            visual={dialog.visual}
            onUpdated={() => refreshAmbiance()}
            onClose={() => setDialog({ type: "None" })}
          />
        );
      case "DeleteVisual":
        return (
          <DeleteVisualDialog
            ambianceId={dialog.ambianceId}
            groupId={dialog.groupId}
            visual={dialog.visual}
            onDeleted={() => refreshAmbiance()}
            onClose={() => setDialog({ type: "None" })}
          />
        );
      case "EditAmbiance":
        return (
          <EditAmbianceDialog
            ambiance={dialog.ambiance}
            onUpdated={() => refreshAmbiance()}
            onClose={() => setDialog({ type: "None" })}
          />
        );
      case "DeleteAmbiance":
        return (
          <DeleteAmbianceDialog
            ambianceId={dialog.ambianceId}
            onClose={() => setDialog({ type: "None" })}
          />
        );
      default:
        return null;
    }
  }

  return (
    <EditLayout>
      <Header
        apps={props.config.appLinks}
        actions={
          ambiance.type == "Success" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  fontSize: "var(--font-large)",
                  marginRight: "var(--size-2)",
                }}
              >
                {ambiance.value.name}
              </div>
              <IconButton
                key="edit"
                color="transparent"
                size="small"
                icon={faPencilAlt}
                title="Edit ambiance"
                onClick={() =>
                  setDialog({
                    type: "EditAmbiance",
                    ambiance: ambiance.value,
                  })
                }
              />
              <IconButton
                key="delete"
                color="transparent"
                size="small"
                icon={faTrashAlt}
                title="Delete ambiance"
                onClick={() =>
                  setDialog({
                    type: "DeleteAmbiance",
                    ambianceId: ambiance.value.id,
                  })
                }
              />
            </div>
          ) : undefined
        }
      />
      {ambiance.type == "Success" && (
        <Visuals
          ambiance={ambiance.value}
          setDialog={setDialog}
          onSelected={() => refreshActives()}
          onMoved={() => refreshAmbiance()}
        />
      )}
      {ambiance.type == "Success" && (
        <Sounds
          ambiance={ambiance.value}
          setDialog={setDialog}
          onSelected={() => refreshActives()}
        />
      )}
      <div className="preview">
        <Row align="center">
          <Heading level={2}>Live View</Heading>
          <IconButton
            color="transparent"
            icon={faExternalLinkAlt}
            title="Open fullscreen view"
            onClick={() => {
              window.open(window.location.href.replace("/edit", "/view"));
            }}
          />
          <div style={{ flexGrow: 1 }} />
          <IconButton
            color="transparent"
            icon={faTimes}
            title="Clear active visuals and sounds"
            onClick={() => {
              deleteActives(params.id)
                .then(() => refreshActives())
                .catch((error: Error) =>
                  // TODO: Improve
                  alert(error.reason)
                );
            }}
          />
        </Row>
        {ambiance.type == "Success" && actives.type == "Success" && (
          <div
            style={{
              marginTop: "var(--size-2)",
              position: "relative",
              width: "100%",
              paddingBottom: "56.25%",
              background: "black",
              zIndex: 0,
            }}
          >
            <VisualViews ambiance={ambiance.value} actives={actives.value} />
            <SoundControls
              ambiance={ambiance.value}
              actives={actives.value}
              onChange={refreshActives}
            />
          </div>
        )}
      </div>
      {renderDialog()}
    </EditLayout>
  );
}
