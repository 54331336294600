import * as React from "react";
import * as ReactDOM from "react-dom";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { getConfig } from "./api/configApi";
import { SelectAmbiancePage } from "./pages/SelectAmbiance";
import { Config, Error } from "./api/models";
import { CreateAmbiancePage } from "./pages/CreateAmbiancePage";
import { EditAmbiancePage } from "./pages/EditAmbiance";
import { ViewAmbiancePage } from "./pages/ViewAmbiance";
import { SignInPage } from "./pages/SignInPage";
import { LoadingPage } from "./pages/LoadingPage";
import { ErrorPage } from "./pages/ErrorPage";
import { useResource } from "./hooks/useResource";
import "./config";

function App(): JSX.Element {
  const [config] = useResource<Config, Error>(getConfig);

  function render(): JSX.Element {
    switch (config.type) {
      case "Loading":
        return <LoadingPage />;
      case "Success":
        if (config.value.user) {
          return (
            <HashRouter>
              <Switch>
                <Route exact path="/">
                  <SelectAmbiancePage config={config.value} />
                </Route>
                <Route exact path="/create">
                  <CreateAmbiancePage config={config.value} />
                </Route>
                <Route exact path="/ambiance/:id/edit">
                  <EditAmbiancePage config={config.value} />
                </Route>
                <Route exact path="/ambiance/:id/view">
                  <ViewAmbiancePage />
                </Route>
                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </HashRouter>
          );
        } else {
          return (
            <HashRouter>
              <Switch>
                <Route exact path="/ambiance/:id/view">
                  <ViewAmbiancePage />
                </Route>
                <Route>
                  <SignInPage config={config.value} />
                </Route>
              </Switch>
            </HashRouter>
          );
        }
      case "Failure":
        return <ErrorPage error={config.error} />;
    }
  }

  return render();
}

ReactDOM.render(<App />, document.getElementById("app"));
