import { DependencyList, useEffect, useState } from "react";

export function useResource<VALUE, ERROR>(
  loader: () => Promise<VALUE>,
  deps: DependencyList = []
): [Resource<VALUE, ERROR>, () => void] {
  // Create state
  const [resource, setResource] = useState<Resource<VALUE, ERROR>>({
    type: "Loading",
  });

  // Create refresh function
  function refreshResource() {
    loader()
      .then((value: VALUE) => setResource({ type: "Success", value: value }))
      .catch((error: ERROR) => setResource({ type: "Failure", error: error }));
  }

  // Refresh now and when deps change
  useEffect(refreshResource, deps);

  // Return resource and refresh function
  return [resource, refreshResource];
}

export type Resource<Value, Error> = Loading | Success<Value> | Failure<Error>;

type Loading = {
  type: "Loading";
};

type Success<Value> = {
  type: "Success";
  value: Value;
};

type Failure<Error> = {
  type: "Failure";
  error: Error;
};
