import * as React from "react";
import { useState } from "react";
import { Actives, Ambiance } from "../../api/models";
import { VisualView } from "./VisualView";

type Props = {
  ambiance: Ambiance;
  actives: Actives;
};

export function VisualViews(props: Props): JSX.Element {
  const [loadedUrls, setLoadedUrls] = useState<string[]>([]);
  const [loadingUrls, setLoadingUrls] = useState<string[]>([]);

  function isLoaded(url: string): boolean {
    return loadedUrls.some((loaded) => loaded == url);
  }

  function isLoading(url: string): boolean {
    return loadingUrls.some((loading) => loading == url);
  }

  function load(url: string): boolean {
    if (isLoaded(url) || isLoading(url)) {
      return true;
    }
    setLoadingUrls((urls) => urls.concat(url));

    const image = new Image();
    image.onload = () => {
      // Render the loaded image for 100 ms before swapping to prevent flickering
      setTimeout(() => {
        setLoadedUrls((urls) => urls.concat(url));
        setLoadingUrls((urls) => urls.filter((loading) => loading != url));
      }, 100);
    };
    image.onerror = () => {
      console.log("Failed to load", url);
    };
    image.src = url;

    return true;
  }

  return (
    <>
      {loadingUrls.map((url) => (
        <VisualView
          key={url}
          backgroundColor="black"
          containImage={false}
          crossfadeMs={0}
          imageUrl={url}
          visible={false}
          above={false}
        />
      ))}
      {props.ambiance.visualGroups.map((visualGroup) =>
        visualGroup.visuals.map((visual) => (
          <VisualView
            key={visual.id}
            backgroundColor={visualGroup.backgroundColor}
            containImage={visualGroup.containImage}
            crossfadeMs={visualGroup.crossfadeMs}
            imageUrl={
              isLoaded(visual.imageUrl) ? visual.imageUrl : visual.thumbnailUrl
            }
            visible={
              visual.id == props.actives.visual?.id && load(visual.imageUrl)
            }
            above={visual.id == props.actives.visual?.id}
          />
        ))
      )}
    </>
  );
}
