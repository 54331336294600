import { get, post } from "./request";
import { CreateFile, File, Usage } from "./models";

export function getUsage(): Promise<Usage> {
  return get("/api/v1/files/usage");
}

export function uploadFile(file: CreateFile, compress?: string): Promise<File> {
  if (compress) {
    return post(`/api/v1/files?compress=${compress}`, file);
  } else {
    return post("/api/v1/files", file);
  }
}
