export function timestamp() {
  const startMs = Date.now();

  function millisSince(): number {
    return Date.now() - startMs;
  }

  function secondsSince(): number {
    return millisSince() / 1000;
  }

  return { millisSince, secondsSince };
}
