export type Validator<T> = (value: T) => string | undefined;
export type Rule<T> = [(value: T) => boolean, string];

export function validator<T>(rules: Rule<T>[]): Validator<T> {
  return (value: T) => {
    for (const [test, message] of rules) {
      if (!test(value)) {
        return message;
      }
    }
    return undefined;
  };
}

const integerPattern = /^-?[0-9]+$/;
const floatPattern = /^-?[0-9]+(\.[0-9]+)?$/;

export function required<T>(): (value: T) => boolean {
  return (value) => !!value;
}

export function length<T>(
  min?: number,
  max?: number
): (value: ArrayLike<T>) => boolean {
  return (value) =>
    (min === undefined || value.length >= min) &&
    (max === undefined || value.length <= max);
}

export function int(min?: number, max?: number): (value: string) => boolean {
  return (value) =>
    !!value.match(integerPattern) &&
    (min === undefined || parseInt(value) >= min) &&
    (max === undefined || parseInt(value) <= max);
}

export function float(min?: number, max?: number): (value: string) => boolean {
  return (value) =>
    !!value.match(floatPattern) &&
    (min === undefined || parseFloat(value) >= min) &&
    (max === undefined || parseFloat(value) <= max);
}

export function regex(pattern: RegExp): (value: string) => boolean {
  return (value) => !!value.match(pattern);
}
