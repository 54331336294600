export function toDataUrl(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function () {
      reject();
    };
    reader.readAsDataURL(file);
  });
}

export function toFile(dataUrl: string) {
  const [prefix, data] = dataUrl.split(",");
  return {
    mediatype: prefix.replace("data:", "").replace(";base64", ""),
    data: data,
  };
}
