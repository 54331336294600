import * as React from "react";
import { useState } from "react";
import { IconButton } from "@lundal/nyx-react";
import { Visual, VisualGroup } from "../../api/models";
import { VisualThumbnail } from "./VisualThumbnail";
import {
  faChevronDown,
  faChevronRight,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./VisualGroupSection.scss";
import { AddVisual } from "./AddVisual";

type Props = {
  group: VisualGroup;
  onEditGroup: () => void;
  onDeleteGroup: () => void;
  onAddVisual: () => void;
  onEditVisual: (visual: Visual) => void;
  onDeleteVisual: (visual: Visual) => void;
  onSelectVisual: (visual: Visual) => void;
  onMoveVisual: (id: string, groupId: string, position: number) => void;
};

export function VisualGroupSection(props: Props): JSX.Element {
  const [expanded, setExpanded] = useState(true);

  return (
    <div className="visual-group">
      <div className="header">
        <IconButton
          color="transparent"
          size="small"
          icon={expanded ? faChevronDown : faChevronRight}
          title={expanded ? "Collapse" : "Expand"}
          onClick={() => setExpanded(!expanded)}
        />
        <span className="name">{props.group.name}</span>
        <IconButton
          color="transparent"
          size="small"
          icon={faPencilAlt}
          title={`Edit '${props.group.name}'`}
          onClick={props.onEditGroup}
        />
        <IconButton
          color="transparent"
          size="small"
          icon={faTrashAlt}
          title={`Delete '${props.group.name}'`}
          onClick={props.onDeleteGroup}
        />
      </div>
      {expanded && (
        <div className="visual-thumbnails">
          {props.group.visuals.map((visual, index) => (
            <VisualThumbnail
              key={visual.id}
              group={props.group}
              visual={visual}
              position={index}
              onEdit={() => props.onEditVisual(visual)}
              onDelete={() => props.onDeleteVisual(visual)}
              onSelect={() => props.onSelectVisual(visual)}
              onMoveVisual={props.onMoveVisual}
            />
          ))}
          <AddVisual
            group={props.group}
            position={props.group.visuals.length}
            onAddVisual={props.onAddVisual}
            onMoveVisual={props.onMoveVisual}
          />
        </div>
      )}
    </div>
  );
}
