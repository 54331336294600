import * as React from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Column,
  Dialog,
  Heading,
  Message,
  Row,
} from "@lundal/nyx-react";
import { Form } from "../../components/Form";
import { deleteAmbiance } from "../../api/ambianceApi";
import { Error } from "../../api/models";
import { useForm } from "../../hooks/useForm";

type Props = {
  ambianceId: string;
  onClose: () => void;
};

export function DeleteAmbianceDialog(props: Props): JSX.Element {
  const form = useForm<{}, {}, void, Error>(
    {},
    () => ({}),
    () => deleteAmbiance(props.ambianceId)
  );

  return (
    <Dialog onDismiss={() => props.onClose()}>
      <Form onSubmit={form.submit}>
        <Column>
          <Heading level={2}>Delete Ambiance</Heading>
          <p>Are you sure you want to delete this ambiance?</p>
          <div />
          <Row>
            <Button color="red" label="Delete ambiance" />
            <Button label="Cancel" onClick={() => props.onClose()} />
          </Row>
          {form.state.type === "Success" && <Redirect to="/" />}
          {form.state.type == "Failure" && (
            <Message type="error" text={form.state.error.reason} />
          )}
        </Column>
      </Form>
    </Dialog>
  );
}
