import * as React from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  CenterLayout,
  Column,
  Heading,
  Input,
  Message,
  TextBox,
} from "@lundal/nyx-react";
import { Header } from "../components/Header";
import { Ambiance, Config, Error } from "../api/models";
import { createAmbiance } from "../api/ambianceApi";
import { Form } from "../components/Form";
import { useForm } from "../hooks/useForm";
import { validateName } from "../common/Validators";

type Fields = {
  name: string;
};

type Errors = {
  name?: string;
};

type Props = {
  config: Config;
};

export function CreateAmbiancePage(props: Props): JSX.Element {
  const form = useForm<Fields, Errors, Ambiance, Error>(
    { name: "" },
    (fields) => ({ name: validateName(fields.name) }),
    (fields) => createAmbiance({ name: fields.name })
  );

  const header = <Header apps={props.config.appLinks} />;
  const main = (
    <main>
      <Form onSubmit={form.submit}>
        <Column>
          <Heading level={2}>Create Ambiance</Heading>
          <Input
            id="name"
            label="Name"
            help="The name of your ambiance"
            error={form.errors.name}
            input={
              <TextBox
                value={form.fields.name}
                onChange={(value) => form.update({ name: value })}
              />
            }
          />
          <Button
            color={form.valid ? "white" : undefined}
            label="Create ambiance"
          />
          {form.state.type === "Success" && (
            <Redirect to={`/ambiance/${form.state.value.id}/edit`} />
          )}
          {form.state.type === "Failure" && (
            <Message type="error" text={form.state.error.reason} />
          )}
        </Column>
      </Form>
    </main>
  );

  return <CenterLayout header={header} main={main} />;
}
